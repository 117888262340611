import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AuthService } from "./shared/services/auth.service";

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { HomeComponent } from './components/home/home.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AngularFirestore, FirestoreSettingsToken } from 'angularfire2/firestore';
import { CurrentInterestComponent } from './components/current-interest/current-interest.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ConcernCurrentComponent } from './components/concern-current/concern-current.component';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HourlyHistoryComponent } from './components/hourly-history/hourly-history.component';
import { ResearchRequestComponent } from './components/research-request/research-request.component';
import { DailyHistoryComponent } from './components/daily-history/daily-history.component';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { Md5 } from 'ts-md5/dist/md5';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WeeklyHistoryComponent } from './components/weekly-history/weekly-history.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    HomeComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    CurrentInterestComponent,
    ConcernCurrentComponent,
    NavbarComponent,
    HourlyHistoryComponent,
    ResearchRequestComponent,
    DailyHistoryComponent,
    WeeklyHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    GoogleChartsModule.forRoot(),
    HttpClientModule,
    NgbModule,
    NgxChartsModule,
    BrowserAnimationsModule
  ],
  providers: [
    AuthService,
    AngularFirestore,
    GoogleAnalyticsService,
    Md5
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
