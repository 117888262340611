import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from "../../shared/services/auth.service";
import { IPostOutput } from '../../../../server/interfaces';

@Injectable({
  providedIn: 'root'
})
export class GetHourlyHistoryService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getHourlyHistory(): Observable<IPostOutput> {
    const params = new HttpParams()
      .set('Authorization', this.authService.GetToken());
    let queryStr = '?Authorization=' + this.authService.GetToken();
    //const url = environment.api.baseUrl + '/api/getHourlyHistory' + queryStr;
    //const url = 'http://localhost:9000' + '/api/getHourlyHistory' + queryStr;
    const url = 'https://reddit-endangered-species.uc.r.appspot.com' + '/api/getHourlyHistory' + queryStr;
    //console.log('getHourlyHistory URL called');
    //console.log(url);
    return this.http.get<IPostOutput>(url);
  }
}
