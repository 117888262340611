// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBmWyBB0CWppa5zaIiaVNqbX4c-IygbFzE",
    authDomain: "reddit-endangered-species.firebaseapp.com",
    projectId: "reddit-endangered-species",
    storageBucket: "reddit-endangered-species.appspot.com",
    messagingSenderId: "661614691845",
    appId: "1:661614691845:web:6bb39744cc437037b23e6c"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
