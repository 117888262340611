<div id="chartContainer" *ngIf="chart">
  <ngx-charts-line-chart
  [view]="view"
  [scheme]="colorScheme"
  [results]="data"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [autoScale]="autoScale"
  [timeline]="timeline"
  (select)="onSelect($event)">
</ngx-charts-line-chart>
<!--
<google-chart #googlechart  
   [title]="chart.title"  
   [type]="chart.type"  
   [data]="chart.data"   
   [options]="chart.options"  
   [width]="chart.width"  
   [height]="chart.height">  
</google-chart>
-->
</div>