import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concern-current',
  templateUrl: './concern-current.component.html',
  styleUrls: ['./concern-current.component.css']
})
export class ConcernCurrentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
