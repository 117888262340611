<!--
<google-chart #chart  
   [title]="title"  
   [type]="type"  
   [data]="data"  
   [options]="options">
</google-chart>
-->
<div *ngIf="chart">
   <google-chart #googlechart 
      [title]="chart.title" 
      [type]="chart.type" 
      [data]="chart.data" 
      [options]="chart.options">
   </google-chart>
</div>