import { Component, HostListener, OnInit } from '@angular/core';
import { GoogleChartComponent, GoogleChartsModule } from 'angular-google-charts';
import { IRedditPost } from 'server/interfaces';
import { GetWeeklyHistoryService } from 'src/app/shared/services/get-weekly-history.service'; 
import { Observable, Subject, throwError } from 'rxjs';
import { componentFactoryName } from '@angular/compiler';
import { firestore } from 'firebase';

@Component({
  selector: 'app-weekly-history',
  templateUrl: './weekly-history.component.html',
  styleUrls: ['./weekly-history.component.scss']
})
export class WeeklyHistoryComponent implements OnInit {

  chart = null;
  data: any[] = null;
  autoScale = true;
  view: any[] = [700, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Time';
  showYAxisLabel = true;
  yAxisLabel = 'Num Posts';
  timeline = true;
  colorScheme = { domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'] };
  
  constructor(
    public gws: GetWeeklyHistoryService
  ) { }

  onSelect(event) {
    console.log(event);
  }

  setView() {
    let width = document.getElementById("chartContainer").clientWidth;
    let height = document.getElementById("chartContainer").clientHeight;
    if (width < 400) { width = 400; }
    if (height < 300) { height = 300; }
    this.view = [width, height];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.setView();
  }

  ngOnInit(): void {
    this.gws.getWeeklyHistory()
    .subscribe({
      next: x => {
        console.log('weekly-history-component.ts x.output:')
        console.log(x.output);
        let newSeries = [];
        if (!this.data) {
            for (let i=0; i<x.output.length; i++) {
              let thisObj = {
                name: new Date(x.output[i].timestamp.seconds * 1000),
                value: x.output[i].env_posts
              }
              newSeries.push(thisObj);
            }
            let newDataObjArr = [];
            let newDataObj = {
              name: "Environmental Posts",
              series: newSeries
            }
            newDataObjArr.push(newDataObj);
            //console.log('newDataObjArr');
            //console.log(newDataObjArr);
            this.data = newDataObjArr;
        }
        this.chart = true;
      },
      error: err => console.error(err),
      complete: () => {
        //console.log('done');
        //console.log(JSON.stringify(this.chart));
      },
    });
  }

}
