import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ABTestService } from '../../shared/services/ab-test.service';
import { Observable } from 'rxjs';
import { IABConfig } from 'server/interfaces';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  closeResult = '';
  config$: Observable<any>;
  test_var: string;
  test_val: string;
  
  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    private _abTestService: ABTestService,
    private router: Router 
  ) { }

  ngOnInit(): void {
    this._abTestService.getABConfig()
    .pipe(first())
    .subscribe(res => {
      for (let i=0; i < res.results.length; i++) {
          if (res.results[i].variable === 'HomeComponentGettingStartedButtonColor') {
              this.test_var = res.results[i].variable;
              this.test_val = res.results[i].value;
          }
      }
      this.config$ = res;
      this._abTestService.setABResults(this.test_var, this.test_val, 'presented')
      .pipe(first())
      .subscribe(res => {
          console.log('');
      })
    })
  }

  recordTest(): void {
    this._abTestService.setABResults(
        this.test_var,
        this.test_val,
        'used'
    )
    .pipe(first())
    .subscribe(res => {
      //console.log('subscribed to setABResults: ' + res);
  })
    this.router.navigate(['../sign-in'])
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
