<nav class="navbar navbar-expand-md navbar-dark fixed-top" id="mainNav">
    <img class="logo-img" src="../../../assets/img/bee.png" alt="Concernium Logo" href="#">
    <a class="navbar-brand" href="#">
        <img class="logo" src="../../../assets/img/concernium_name_transparent.png" alt="Concernium.org" href="#">
    </a>
</nav>

  <!--
  <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="id01" href="#" ngbDropdownToggle>Files</a>
        <div class="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu>
          <a class="dropdown-item" href="#">HTML</a>
          <a class="dropdown-item" href="#">TS</a>
          <a class="dropdown-item" href="#">JS</a>
        </div>
      </li>
    </ul>
  </div>
  -->

