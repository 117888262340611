
<div class="navbar-container">
    <app-navbar></app-navbar>
</div>


<div class="research-request-container d-flex justify-content-center form-top">
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdvomEFkmP7FYAEHzIRKvCdzUISuRncCssUD5xXNKgrrVfveA/viewform?embedded=true" width="640" height="1043" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</div>

<div class="research-request-container d-flex justify-content-center form-bottom">
    <a routerLink='../home'>
        <button type="button" class="btn btn-secondary">Back to the Home Page!</button>
    </a>
</div>

