import { Component, HostListener, OnInit } from '@angular/core';
import { GoogleChartComponent, GoogleChartsModule } from 'angular-google-charts';
import { IRedditPost } from 'server/interfaces';
import { GetDailyHistoryService } from 'src/app/shared/services/get-daily-history.service'; 
import { Observable, Subject, throwError } from 'rxjs';
import { componentFactoryName } from '@angular/compiler';
import { firestore } from 'firebase';

@Component({
  selector: 'app-daily-history',
  templateUrl: './daily-history.component.html',
  styleUrls: ['./daily-history.component.scss']
})
export class DailyHistoryComponent implements OnInit {
 
  chart = null;
  data: any[] = null;
  autoScale = true;
  view: any[] = [700, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Time';
  showYAxisLabel = true;
  yAxisLabel = 'Num Posts';
  timeline = true;
  colorScheme = { domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'] };

  constructor(
    public ghs: GetDailyHistoryService
  ) { }

  onSelect(event) {
    console.log(event);
  }

  setView() {
    let width = document.getElementById("chartContainer").clientWidth;
    let height = document.getElementById("chartContainer").clientHeight;
    if (width < 400) { width = 400; }
    if (height < 300) { height = 300; }
    this.view = [width, height];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.setView();
  }

  ngOnInit(): void {
    this.ghs.getDailyHistory()
    .subscribe({
      next: x => {
        //console.log('daily-history-component.ts x.output:')
        //console.log(x.output);
        let newSeries = [];
        if (!this.data) {
            // parse x.output
            for (let i=0; i<x.output.length; i++) {
              let thisObj = {
                name: new Date(x.output[i].timestamp.seconds * 1000),
                value: x.output[i].env_posts
              }
              newSeries.push(thisObj);
            }
            let newDataObjArr = [];
            let newDataObj = {
              name: "Environmental Posts",
              series: newSeries
            }
            newDataObjArr.push(newDataObj);
            //console.log('newDataObjArr');
            //console.log(newDataObjArr);
            this.data = newDataObjArr;
        }
        this.chart = true;
      },
      error: err => console.error('ERROR: ' + err),
      complete: () => {
        //console.log('done');
        //console.log(JSON.stringify(this.chart));
      },
    });
  }
/* OLD CODE BELOW
  setChart() {
    let w = window.innerWidth * .9;
    let h = w * .7;
    let history_data = [];
    for (let i=0; i<this.posts.length; i++) {
      let thisPost = this.posts[i];
      let d = new Date(0);
      d.setUTCSeconds(thisPost.timestamp.seconds);
      //let datetext = d.toTimeString();
      //datetext = datetext.split(' ')[0];
      //let datehours = d.getHours();
      //let datetext = datehours + ':00'
      let localDate = d.toLocaleString();
      let localDate2 = ((d.getMonth() > 8) ? (d.getMonth() + 1) : ('0' + (d.getMonth() + 1))) + '/' + ((d.getDate() > 9) ? d.getDate() : ('0' + d.getDate())) + '/' + d.getFullYear()
      let element = [
        localDate2,
        thisPost.env_posts
      ];
      history_data.push(element);
    }
    //console.log('history_data');
    //console.log(JSON.stringify(history_data));
    
    this.chart = {
      title: "",
      type: "LineChart",
      options: {
        "backgroundColor": "transparent",
        legend:{position:'none'}
      },
      data: history_data,
      width: w,
      height: h
    }  
  }
*/
}
