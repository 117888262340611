import { Component, HostListener, OnInit } from '@angular/core';
import { GoogleChartComponent, GoogleChartsModule } from 'angular-google-charts';
import { IRedditPost } from 'server/interfaces';
import { GetHourlyHistoryService } from 'src/app/shared/services/get-hourly-history.service'; 
import { Observable, Subject, throwError } from 'rxjs';
import { componentFactoryName } from '@angular/compiler';
import { firestore } from 'firebase';
import * as firebase from 'firebase';

@Component({
  selector: 'app-hourly-history',
  templateUrl: './hourly-history.component.html',
  styleUrls: ['./hourly-history.component.scss']
})
export class HourlyHistoryComponent implements OnInit {

  // //////////////////////////////////////////////////////////
  // Data
  // //////////////////////////////////////////////////////////
  // this.chart shouldn't be set to true until the data comes back
  chart = null;
  // the data can be stored in an element named "data"
  data: any[] = null;

/*
  single: any[];
  multi: any[] = [
  {
    name: 'Cyan',
    series: [
      {
        name: 5,
        value: 2650
      },
      {
        name: 10,
        value: 2800      },
      {
        name: 15,
        value: 2000
      }
    ]
  },
  {
    name: 'Yellow',
    series: [
      {
        name: 5,
        value: 2500
      },
      {
        name: 10,
        value: 3100
      },
      {
        name: 15,
        value: 2350
      }
    ]
  }
];
*/

// //////////////////////////////////////////////////////////
// Display Options
// //////////////////////////////////////////////////////////
view: any[] = [700, 400];
showXAxis = true;
showYAxis = true;
gradient = false;
showLegend = false;
showXAxisLabel = true;
xAxisLabel = 'Time';
showYAxisLabel = true;
yAxisLabel = 'Num Posts';
timeline = true;
colorScheme = {
  domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
};
autoScale = true;


constructor(
  public ghhs: GetHourlyHistoryService
) {
}

onSelect(event) {
  console.log(event);
}

setView() {
  let width = document.getElementById("chartContainer").clientWidth;
  let height = document.getElementById("chartContainer").clientHeight;
  if (width < 400) { width = 400; }
  if (height < 300) { height = 300; }
  this.view = [width, height];
}

@HostListener('window:resize', ['$event'])
onResize(event) {
    this.setView();
}

ngOnInit(): void {
  this.ghhs.getHourlyHistory()
  .subscribe({
      next: x => {
        //console.log('hourly-history-component.ts x.output:')
        //console.log(x.output);
        let newSeries = [];
        if (!this.data) {
            // parse x.output
            for (let i=0; i<x.output.length; i++) {
              let thisObj = {
                name: new Date(x.output[i].timestamp.seconds * 1000),
                value: x.output[i].env_posts
              }
              newSeries.push(thisObj);
            }
            let newDataObjArr = [];
            let newDataObj = {
              name: "Environmental Posts",
              series: newSeries
            }
            newDataObjArr.push(newDataObj);
            //console.log('newDataObjArr');
            //console.log(newDataObjArr);
            this.data = newDataObjArr;
        }
        this.chart = true;
      },
      error: err => console.error('ERROR: ' + err),
      complete: () => {
        //console.log('done');
        //console.log(JSON.stringify(this.chart));
      },
    });
}

// OLD CODE BELOW
/*
  googlechart: GoogleChartComponent;
  goggle: GoogleChartsModule;
  chart = null;
  public posts: any = null; 
  
  constructor(
    public ghhs: GetHourlyHistoryService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.setChart();
  }
    
  ngOnInit(): void {
    this.ghhs.getHourlyHistory()
    .subscribe({
        next: x => {
          //console.log('hourly-history-component.ts x.output:')
          //console.log(x.output);
          if (!this.posts) {
              this.posts = x.output;
          }
          this.setChart();
        },
        error: err => console.error('ERROR: ' + err),
        complete: () => {
          //console.log('done');
          //console.log(JSON.stringify(this.chart));
        },
      });
  }

  setChart() {
    let w = window.innerWidth * .9;
    let h = w * .7;
    let history_data = [];
    for (let i=0; i<this.posts.length; i++) {
      let thisPost = this.posts[i];
      let d = new Date(0);
      d.setUTCSeconds(thisPost.timestamp.seconds);
      //let datetext = d.toTimeString();
      //datetext = datetext.split(' ')[0];
      let datehours = d.getHours();
      let datetext = datehours + ':00'
      //let localDate = d.toLocaleString();
      let element = [
        datetext,
        thisPost.env_posts
      ];
      history_data.push(element);
    }
    //console.log('history_data');
    //console.log(JSON.stringify(history_data));
    
    this.chart = {
      title: "",
      type: "LineChart",
      options: {
        "backgroundColor": "transparent",
        legend:{position:'none'}
      },
      data: history_data,
      width: w,
      height: h
    }
    
}
*/



}
