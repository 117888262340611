import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { IRedditPost } from 'server/interfaces';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  posts$: Observable<IRedditPost[]>;
  selectedTabId = "currentTab";

  constructor(
    public authService: AuthService,
    private db: AngularFirestore,
    private ga: GoogleAnalyticsService
  ) { 
    this.posts$ = this.db.collection<IRedditPost>('reddit-endangered-species')
    .snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as IRedditPost;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
  
  // Change the display of the tab (adding a class to show it as the active tab)
  // Change the chart displayed (by recording the currently selected tab and using it 
  //   in the template to decide which component to display)
  handleNavClick(event) {
      // Remove the side-link-active class from all the tabs
      document.getElementsByClassName("side-link-active")[0].classList.remove("side-link-active");

      // Get the id of the element that fired the event
      const target = event.target || event.srcElement || event.currentTarget;
      const id = target.attributes.id;

      // Record the selected tab's id (which the template will use to decide which)
      // chart component to display
      this.selectedTabId = id.value;
      //console.log('this.selectedTabId: ' + this.selectedTabId);

      // Add "side-link-active" to the selected tab's classlist
      document.getElementById(this.selectedTabId).classList.add("side-link-active");

      // Let GoogleAnalytics know that the user switched the time-period displayed
      let tabValue = 0
      if (this.selectedTabId == 'weeklyTab') {
        tabValue = 1;
      } else if (this.selectedTabId == 'monthlyTab') {
        tabValue = 2;
      }
      this.ga.eventEmitter("change-time-period", "dashboard", "navigation", "click", tabValue);
      
  }

  ngOnInit(): void {
    //this.posts$.subscribe(data => console.log(data));
  }

}
