<!-- navbar -->
<div class="navbar-container">
    <app-navbar></app-navbar>
</div>

<!-- content -->
<div class="container">


    <div class="row">

        <div class="col-sm-2">
            <table class="table dashboard-table">
                <tbody>
                    <tr><td><span id="currentTab" class="side-link side-link-active" (click)="handleNavClick($event)">Current</span></td></tr>
                    <tr><td><span id="weeklyTab" class="side-link" (click)="handleNavClick($event)">Past Week</span></td></tr>
                    <tr><td><span id="monthlyTab" class="side-link" (click)="handleNavClick($event)">Past Month</span></td></tr>
                </tbody>
            </table>
        </div>

        <div class="col-sm-9 text-center page-content">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <span class="dashboard-page-title">Endangered Species Posts</span>
                        <br>
                        <span class="dashboard-page-desc">
                            Number of Social Media posts that
                            mention endangered species, conservation,
                            or related terms over the selected time period
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div *ngIf='selectedTabId=="currentTab"'>
                            <app-hourly-history></app-hourly-history>
                        </div>
                        <div *ngIf='selectedTabId=="weeklyTab"'>
                            <app-daily-history></app-daily-history>
                        </div>
                        <div *ngIf='selectedTabId=="monthlyTab"'>
                            <p>&nbsp;</p>
                            <app-weekly-history></app-weekly-history>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-1">
            
        </div>
    </div>
</div>

<!-- footer -->
<footer class="container-fluid">
    <div class="row">
        <div class="col-sm-12 text-center">
            <br>
            <p class="footer-text">Copyright &copy; concernium.org</p>
        </div>
    </div>
</footer>  


  