import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from '../../../environments/environment';
import { AuthService } from "../../shared/services/auth.service";
import { IABConfig } from '../../../../server/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ABTestService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getABConfig(): any {
    //const url = environment.api.baseUrl + '/api/getABConfig';
    //const url = 'http://localhost:9000' + '/api/getABConfig';
    const url = 'https://reddit-endangered-species.uc.r.appspot.com' + '/api/getABConfig';
    return this.http.get<any>(url);
  }
  
  public setABResults(variable: string, value: string, type: string): any {
    const md5 = new Md5();
    let queryStr = '?';
    const now = new Date();
    const formatted_date = now.toISOString();
    queryStr += '&var=' + variable;
    queryStr += '&tim=' + formatted_date;
    queryStr += '&val=' + value;
    queryStr += '&typ=' + type;
    //const url = environment.api.baseUrl + '/api/setABResults' + queryStr;
    //const url = 'http://localhost:9000' + '/api/setABResults' + queryStr;
    const url = 'https://reddit-endangered-species.uc.r.appspot.com' + '/api/setABResults' + queryStr;
    return this.http.get<string>(url);
  }
  
}
