import { Component, OnInit } from '@angular/core';
import { GoogleChartComponent } from 'angular-google-charts';
import { IRedditPost } from 'server/interfaces';
import { CurrentPostCountsService } from '../../shared/services/current-post-counts.service';
import { Observable, Subject, throwError } from 'rxjs';

@Component({
  selector: 'app-current-interest',
  templateUrl: './current-interest.component.html',
  styleUrls: ['./current-interest.component.css']
})
export class CurrentInterestComponent implements OnInit {

  currentPostsData$: Observable<number>;
  googlechart: GoogleChartComponent;
  percentage: number = null;
  chart = null;
  
  constructor(
    public pcs: CurrentPostCountsService
  ) { }

  setChart(percentage){
      this.chart = {
        title: 'Current Interest Levels',
        type: 'Gauge',
        data: [
          ['Current', percentage.output]
        ],
        options: {
          width: 400,
          height: 120,
          greenFrom: 0,
          greenTo: 75,
          greenColor: "#00c020",
          redFrom: 90,
          redTo: 100,
          redColor: "#800020",
          yellowFrom: 75,
          yellowTo: 90,
          yellowColor: "#fdd219",
          minorTicks: 5
        }
      };
      console.log('percentage.output: ' + percentage.output);
  };

  ngOnInit(): void {
    //this.currentPostsData$ = this.pcs.getPostPercentage();
    //this.currentPostsData$
    this.pcs.getPostPercentage()
      .subscribe({
          next: x => this.setChart(x),
          error: err => console.error('ERROR: ' + err),
          complete: () => {
            console.log('done');
            console.log(JSON.stringify(this.chart));
          },
        });
  }

}
