<div class="navbar-container">
    <app-navbar></app-navbar>
</div>

<div class="container-fluid text-center page-content">    

    <div class="row content">
        
        <!-- Jumbotron -->
        <div class="col-sm-12"> 
            <div class="jumbotron jumbotron-fluid">
                <div class="container text-center jumbotron-text-box">
                    <h3>&nbsp;</h3>
                    <h1 class="jumbotron-title">Thanks for visiting, but we've closed down operations.</h1>
                    <h3>&nbsp;</h3>
                </div>
            </div>
        </div>
    </div>
    
</div>

<footer class="container-fluid">
    <div class="row">
        <div class="col-sm-12 text-center">
            <br>
            <p class="footer-text">Copyright &copy; concernium.org</p>
        </div>
    </div>
</footer>  




